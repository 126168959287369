import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';

import { DataTable, Icon, PaginatedList, Spinner, Timestamp } from 'components/common';
import DateTime from 'logic/datetimes/DateTime';
import { sortByDate } from 'util/SortUtils';

import ReportsStore from '../ReportsStore';
import ReportsActions from '../ReportsActions';

import style from './ReportHistory.css';

const ReportHistory = createReactClass({
  displayName: 'ReportHistory',

  propTypes: {
    report: PropTypes.object.isRequired,
  },

  mixins: [Reflux.connect(ReportsStore)],

  componentDidMount() {
    this._resetPageSettings();
    this._fetchHistory(this.props.report);
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.report.id !== nextProps.report.id) {
      this._resetPageSettings();
      this._fetchHistory(nextProps.report);
    }
  },

  _resetPageSettings() {
    this.currentPage = 1;
    this.pageSize = 10;
  },

  _fetchHistory(report) {
    ReportsActions.getHistory(report, (this.currentPage - 1) * this.pageSize, this.pageSize);
  },

  _onChangePaginatedList(page, size) {
    this.currentPage = page;
    this.pageSize = size;
    this._fetchHistory(this.props.report);
  },

  _headerFormatter(header) {
    return <th>{header}</th>;
  },

  _rowFormatter(historyItem, idx) {
    const icon = historyItem.status === 'SUCCESS' ? 'check-circle' : 'times-circle';
    const iconClass = historyItem.status === 'SUCCESS' ? 'text-success' : 'text-danger';

    return (
      <tr key={idx}>
        <td className={style.status}><Icon name={icon} className={iconClass} /></td>
        <td>{historyItem.message}</td>
        <td className={style.date}><Timestamp dateTime={historyItem.generated_at} format={DateTime.Formats.COMPLETE} /></td>
      </tr>
    );
  },

  render() {
    const { history, totalHistoryItems } = this.state;
    if (!history) {
      return <Spinner text="Loading report history..." />;
    }

    const headers = ['Status', 'Message', 'Date'];
    const sortedHistory = history.sort((h1, h2) => sortByDate(h1.generated_at, h2.generated_at, 'desc'));

    return (
      <PaginatedList totalItems={totalHistoryItems}
                     pageSize={this.pageSize}
                     onChange={this._onChangePaginatedList}
                     showPageSizeSelect>
        <DataTable id="report-history"
                   className="table-hover"
                   headers={headers}
                   headerCellFormatter={this._headerFormatter}
                   rows={sortedHistory}
                   dataRowFormatter={this._rowFormatter}
                   noDataText="There are no records of this report being sent."
                   filterLabel=""
                   filterKeys={[]} />
      </PaginatedList>
    );
  },
});

export default ReportHistory;
