// suppress lint warning for example string
/* eslint-disable no-template-curly-in-string */

import PropTypes from 'prop-types';
import React from 'react';

import { Row, Col, Button, Table, Popover, OverlayTrigger } from 'components/graylog';
import { Icon } from 'components/common';
import { LinkContainer } from 'react-router-bootstrap';
import { Input } from 'components/bootstrap';

const style = require('!style/useable!css!./ArchiveBackends.css');

class ArchiveBackendConfigForm extends React.Component {
  static propTypes = {
    backend: PropTypes.object.isRequired,
    checkOutputPath: PropTypes.func.isRequired,
    updateConfiguration: PropTypes.func.isRequired,
    cancelURL: PropTypes.string.isRequired,
  };

  state = {
    backend: this.props.backend,
    pathCheck: undefined,
    pathError: undefined,
  };

  componentDidMount() {
    style.use();
    if (this.state.backend.settings.output_path) {
      this._validateOutputPath(this.state.backend.settings.output_path);
    }
  }

  componentWillUnmount() {
    style.unuse();
  }

  _saveConfiguration = (e) => {
    e.preventDefault();

    this.props.updateConfiguration(this.state.backend);
  };

  _updateConfig = (fieldName, value) => {
    const config = this.state.backend;
    config[fieldName] = value;
    this.setState({ backend: config });
  };

  _onInputChange = (event) => {
    this._updateConfig(event.target.name, event.target.value);
  };

  _validateOutputPath = (path) => {
    this.props.checkOutputPath(path).then((response) => {
      this.setState({ pathCheck: response.result, pathError: null });
    }, (error) => {
      try {
        this.setState({ pathCheck: null, pathError: error.additional.body.message });
      } catch (e) {
        this.setState({ pathCheck: null, pathError: error });
      }
    });
  };

  _onOutputPathChange = (event) => {
    const config = this.state.backend;
    config.settings.output_path = event.target.value;
    this.setState({ backend: config });
    this._validateOutputPath(event.target.value);
  };

  _isNew = () => {
    return !this.state.backend.id || this.state.backend.id.length === 0;
  };

  render() {
    const { backend } = this.state;

    let pathError;
    if (this.state.pathError) {
      pathError = <span><strong>ERROR:</strong> {this.state.pathError}</span>;
    }

    const popover = (
      <Popover id="output-base-path-help" className="popover-wide" title="Output Base Path Help">
        <p>You can use variables in the output path to construct a dynamic value for each archive.</p>
        <p><strong>Available variables</strong></p>
        <Table condensed>
          <thead>
            <tr>
              <th>Variable</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>index-name</td>
              <td>Name of the index that gets archived</td>
            </tr>
            <tr>
              <td>year</td>
              <td>Archival date year</td>
            </tr>
            <tr>
              <td>month</td>
              <td>Archival date month</td>
            </tr>
            <tr>
              <td>day</td>
              <td>Archival date day</td>
            </tr>
            <tr>
              <td>hour</td>
              <td>Archival date hour</td>
            </tr>
            <tr>
              <td>minute</td>
              <td>Archival date minute</td>
            </tr>
            <tr>
              <td>second</td>
              <td>Archival date second</td>
            </tr>
          </tbody>
        </Table>
        <p><strong>Example</strong></p>
        <p><kbd>{'/data/archives/graylog/${year}/${month}'}</kbd></p>
      </Popover>
    );
    const outputPathHelp = (
      <OverlayTrigger trigger="click" rootClose placement="right" overlay={popover}>
        <Button bsStyle="link" className="archive-backend-config-output-path-help"><Icon name="question-circle" fixedWidth /></Button>
      </OverlayTrigger>
    );

    return (
      <div className="archive-backends-config">
        <h2>Backend configuration</h2>

        <form className="form" onSubmit={this._saveConfiguration}>
          <fieldset>
            <Input type="text"
                   id="backend-title"
                   label="Title"
                   name="title"
                   onChange={this._onInputChange}
                   value={backend.title}
                   help="Descriptive name of the backend."
                   autoFocus
                   required />
            <Input type="text"
                   id="backend-description"
                   label="Description"
                   name="description"
                   onChange={this._onInputChange}
                   value={backend.description}
                   help="Backend description." />
            <Input type="text"
                   id="backend-setting-output-path"
                   label={<span>Output base path {outputPathHelp}</span>}
                   name="output_path"
                   onChange={this._onOutputPathChange}
                   value={backend.settings.output_path}
                   bsStyle={this.state.pathError ? 'error' : null}
                   help={pathError || <span>Base path where the archives should be stored. Can <strong>only</strong> be set on backend creation and not be changed later!</span>}
                   disabled={!this._isNew()}
                   required />
          </fieldset>

          <Row>
            <Col md={12}>
              <strong>Example output path: </strong>
              <code>{this.state.pathCheck}</code>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Button type="submit" bsStyle="success" style={{ marginRight: 10 }}>Save</Button>
              <LinkContainer to={this.props.cancelURL}>
                <Button bsStyle="default">Cancel</Button>
              </LinkContainer>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

export default ArchiveBackendConfigForm;
