// @flow strict
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import * as Immutable from 'immutable';
import { trim } from 'lodash';

import { Panel } from 'components/graylog';
import { BootstrapModalForm, Input } from 'components/bootstrap';
import ClipboardButton from 'components/common/ClipboardButton';
import Icon from 'components/common/Icon';
import FormsUtils from 'util/FormsUtils';

import CustomPropTypes from 'views/components/CustomPropTypes';
import type { ParameterMap } from 'views/logic/parameters/Parameter';
import Parameter from 'views/logic/parameters/Parameter';
import QueryEditModeContext from 'views/components/contexts/QueryEditModeContext';
import type { QueryEditMode } from 'views/components/contexts/QueryEditModeContext';

type Props = {
  allowEditingName?: boolean,
  parameters: ParameterMap,
  onClose: () => void,
  onSave: (ParameterMap) => mixed,
  show?: boolean,
  title?: string,
};

type ValidationSuccess = ['success'];
type ValidationWarning = ['warning', string];
type ValidationError = ['error', string];
type ValidationResult = ValidationSuccess | ValidationWarning | ValidationError;

type State = {
  parameters: Immutable.List<Parameter>,
  container?: HTMLElement,
  validationStates: Immutable.Map<string, Immutable.Map<string, Immutable.List<ValidationResult>>>,
};

const IdentifierRegex = /^[\w]+$/;

const backdropForMode = (mode: QueryEditMode): boolean => {
  switch (mode) {
    case 'query': return true;
    case 'widget': return false;
    default: throw new Error(`Invalid query edit mode: ${mode}`);
  }
};

export default class ParameterDeclarationForm extends React.Component<Props, State> {
  modal: any;

  static propTypes = {
    allowEditingName: PropTypes.bool,
    parameters: ImmutablePropTypes.mapOf(CustomPropTypes.instanceOf(Parameter)).isRequired,
    onClose: PropTypes.func,
    onSave: PropTypes.func.isRequired,
    show: PropTypes.bool,
    title: PropTypes.string,
  };

  static defaultProps = {
    allowEditingName: false,
    onClose: () => {},
    show: false,
    title: 'Declare parameters',
  };

  constructor(props: Props) {
    super(props);

    const { parameters } = props;
    this.state = {
      parameters: Immutable.List(parameters.valueSeq()),
      validationStates: this._validateParameters(parameters.valueSeq()),
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { parameters } = nextProps;

    this.setState({
      parameters: Immutable.List(parameters.valueSeq()),
      validationStates: this._validateParameters(parameters.valueSeq()),
    });
  }

  open = () => {
    this.modal.open();
  };

  close = () => {
    this.modal.close();
  };

  handleFormSubmit = (e: SyntheticEvent<*>) => {
    const { onSave } = this.props;
    const { parameters } = this.state;
    e.preventDefault();
    const newParameters = Immutable.Map(parameters.map(p => [p.name, p]));
    onSave(newParameters);
  };

  _validateName = (name: string): ValidationResult => {
    if (!trim(name)) {
      return ['error', 'Must not be empty.'];
    }

    return IdentifierRegex.test(name) ? ['success'] : ['error', 'Only characters, numbers and _ allowed.'];
  };

  _validateTitle = (title: string): ValidationResult => {
    if (!trim(title)) {
      return ['error', 'Must not be empty.'];
    }

    return ['success'];
  };

  _validateParameter = (p: Parameter): { [string]: ValidationResult } => {
    const result = {};
    result.name = this._validateName(p.name);
    result.title = this._validateTitle(p.title);
    return result;
  };

  _validateParameters = (parameters: Immutable.Iterable.Indexed<Parameter>) => {
    const validationStates = {};

    parameters.forEach((p: Parameter, name) => {
      validationStates[name] = this._validateParameter(p);
    });

    return Immutable.fromJS(validationStates);
  };

  _validate = (parameters: Immutable.List<Parameter>) => {
    const validationStates = this._validateParameters(parameters);
    this.setState({ validationStates });
  };

  handleParameterField = (idx: number): ((SyntheticInputEvent<HTMLInputElement>) => void) => {
    const { parameters } = this.state;
    return (e: SyntheticInputEvent<HTMLInputElement>) => {
      const key = e.target.name;
      const value = FormsUtils.getValueFromInput(e.target);

      const newParameters = parameters.update(idx, (parameter: Parameter) => parameter
        // $FlowFixMe: `toBuilder` exists on `ValueParameter`, looking up builder methods by key is not type-safe
        .toBuilder()[key](value)
        .build());

      this._validate(newParameters);

      this.setState({ parameters: newParameters });
    };
  };

  _receiveClipboardRef = (el: ?HTMLElement) => {
    const { container } = this.state;
    if (el && !container) {
      this.setState({ container: el });
    }
  };

  static contextType = QueryEditModeContext;

  renderParameter(parameter: Parameter, idx: number, container: ?HTMLElement) {
    const { name, title, description, defaultValue } = parameter;
    const parameterSyntax = `$${name}$`;
    const { validationStates } = this.state;
    const { allowEditingName } = this.props;
    return (
      <fieldset key={`fieldset-${idx}`}>
        <Input id={`name-${idx}`}
               type="text"
               name="name"
               label="Name"
               disabled={!allowEditingName}
               value={name}
               onChange={this.handleParameterField(idx)}
               bsStyle={validationStates.getIn([`${idx}`, 'name', 0])}
               help={validationStates.getIn([`${idx}`, 'name', 1])}
               required />
        <Input id={`title-${idx}`}
               type="text"
               name="title"
               label="Title"
               value={title}
               onChange={this.handleParameterField(idx)}
               bsStyle={validationStates.getIn([`${idx}`, 'title', 0])}
               help={validationStates.getIn([`${idx}`, 'title', 1])}
               required />
        <Input id={`description-${idx}`}
               type="text"
               name="description"
               label="Description"
               value={description}
               bsStyle={validationStates.getIn([`${idx}`, 'description', 0])}
               help={validationStates.getIn([`${idx}`, 'description', 1])}
               onChange={this.handleParameterField(idx)} />
        <Input id={`default-value-${idx}`}
               type="text"
               name="defaultValue"
               label="Default"
               bsStyle={validationStates.getIn([`${idx}`, 'defaultValue', 0])}
               help={validationStates.getIn([`${idx}`, 'defaultValue', 1])}
               value={defaultValue}
               onChange={this.handleParameterField(idx)} />

        <Panel header="How to use" style={{ marginTop: 20 }}>
          After declaring it, you can use this parameter in all queries by using the{' '}
          <span ref={this._receiveClipboardRef} style={{ whiteSpace: 'nowrap' }}>
            <code>{parameterSyntax}</code>
            {container && (
            <ClipboardButton title={<Icon name="copy" fixedWidth />}
                             bsSize="xsmall"
                             text={parameterSyntax}
                             container={container}
                             buttonTitle="Copy parameter to clipboard" />
            )}
          </span>{' '}
          syntax in your query, whenever you want the value of the parameter to be inserted.
        </Panel>
      </fieldset>
    );
  }

  render() {
    const { parameters, validationStates, container } = this.state;
    const { title, show, onClose } = this.props;

    const errorStates = validationStates.filter(states => states.find(state => state.get(0) === 'error'));
    const submitButtonDisabled = errorStates !== undefined && !errorStates.isEmpty();

    const parameterFields = parameters
      .map((parameter: Parameter, idx: number) => this.renderParameter(parameter, idx, container))
      .valueSeq()
      .toJS();

    const backdrop = backdropForMode(this.context);

    return (
      <BootstrapModalForm ref={(c) => { this.modal = c; }}
                          submitButtonDisabled={submitButtonDisabled}
                          title={title}
                          onModalClose={onClose}
                          show={show}
                          backdrop={backdrop}
                          onSubmitForm={this.handleFormSubmit}>
        {parameterFields}
      </BootstrapModalForm>
    );
  }
}
